import React, { useState } from 'react'
import './Checkbox.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Checkbox(props) {
  const {icon, label, value, name} = props
  const [checked, setChecked] = useState((value==="true"||value===true)?true:false)

  return <div className='Checkbox column center-v'>
    {icon && <label htmlFor={name} className='Checkbox-Icon'><FontAwesomeIcon className='FA pointer' size='2xl' color='rgb(50,50,50)' icon={icon} /></label>}
    <input id={name} name={name} value={!checked} checked={checked} onChange={(e) => setChecked(e.target.checked)} className='m-0' type='checkbox' />
    {label && <div className='Checkbox-Label'><label htmlFor={name}>{label}</label></div>}
</div>
}

