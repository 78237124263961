import { ConfigurationProvider } from "../Contexts/ConfigurationContext"
import { ThemeProvider } from '../Contexts/ThemeContext'
import { TranslationProvider } from '../Contexts/TranslationContext'
import { ModalProvider } from "../Contexts/ModalContext"
import { AuthProvider } from "../Contexts/AuthContext"
import { ExperienceProvider } from "../Contexts/ExperienceContext"

export default function AppContext(props: any) {
    const { children } = props;
    
    return <ConfigurationProvider>
        <ExperienceProvider>
            <ThemeProvider>
                <TranslationProvider>
                    <ModalProvider>
                        <AuthProvider>
                            { children }
                        </AuthProvider>
                    </ModalProvider>
                </TranslationProvider>
            </ThemeProvider>
        </ExperienceProvider>
    </ConfigurationProvider>
}