import './Modal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'

export default function Modal(props: any) {
    const {children, onClose} = props

    return <div className='Modal'>
        <div onClick={onClose} className='Modal-Area' />
        <FontAwesomeIcon onClick={onClose} className='Modal-Close-Button' icon={faXmark} size='2xl' />
        <div className='Modal-Content'>{children}</div>
    </div>;
}