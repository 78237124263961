import {ReactElement, useState} from 'react'
import Input from '../../../Components/Input/Input'
import Button from '../../../Components/Buttons/Button'
import Form from '../../../Components/Form/Form'
import InputType from '../../../Types/InputType'
import ButtonType from '../../../Types/ButtonType'
import { Link, useNavigate  } from 'react-router-dom'
import {useAuth} from '../../../Contexts/AuthContext'
import { useConfiguration } from '../../../Contexts/ConfigurationContext'
import { useTranslation } from '../../../Contexts/TranslationContext'

export default function SignIn(): ReactElement {
    const { setAuthenticated } = useAuth();
    const navigate = useNavigate()

    const [valid, setValid] = useState(false)
    const [processing, setProcessing] = useState(false)
    const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

    const configuration = useConfiguration();
    const {translations} = useTranslation();

    function setValidity(valid: boolean) {
        setValid(valid)
    }

    async function onSignIn(data: any) {
        setProcessing(true)
        
        if (!data) return;
        if (!data['email'] || !reg.test(data['email']) || !data['password']) return;
        
        //const res = await login(data)
        const res = {success: true}
        if (res.success === true) {
            setAuthenticated(true)
            navigate('/account')
        } else {
            alert('Error signin in.')
        }

        setProcessing(false)
    }

    return <main className='SignIn row center center-v w-100 mt-0 pt-0'>
        <div className='SignUp-Form column center h-vh-100 grow-1'>
            <section className='column flex-start'>
                <div className='center-v'>
                    <h1 className='pl-2 mb-1 fg-color-2-text'>{configuration.app_name}</h1>
                    <h1 className='title-auth shine-animation-text mb-8 '>{translations.signin ? translations.signin.toUpperCase() : ""}</h1>
                </div>
                <div className='w-100'>
                    <Form className='column' onSubmit={onSignIn} setValid={setValidity} required>
                        <Input name="email" type={InputType.Text} label={"Email"} placeholder={"Email"} required />
                        <Input name="password" type={InputType.Password} label={"Password"} placeholder={"Password"} required />
                        <Button type={ButtonType.Action} display='secondary' disabled={!valid} className='mt-6 w-50 self-align-end' >Sign In</Button>
                    </Form>
                </div>
                
            </section>
            <a className='Link' href={configuration.routes.authentication.register.value}>{translations.not_registered_yet}</a>
        </div>
    </main>
}
