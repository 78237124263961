import React, { useState } from 'react'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import Input from './Input'
import './Input.css'

export default function InputPassword(props) {
    const {name, required, label, placeholder, value} = props

    const PWD_PATTERN = '^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$'
    const [isPasswordShowed, setIsPasswordShowed] = useState(false)
    const handleButton = () => setIsPasswordShowed(!isPasswordShowed)

    // TODO: Replace hard-coded strings.
    return (
        <Input
            type={isPasswordShowed ? 'text' : 'password'}
            name={name ? name.toLowerCase() : ''}
            label={label ?? 'Password'}
            placeholder={placeholder ?? "Password"}
            pattern={PWD_PATTERN}
            errorMessage={'Password should include min. 8 characters, one capital letter, one lowercase letter and one number'}
            button={isPasswordShowed ? faEyeSlash : faEye}
            handleButton={handleButton}
            required={required ?? false }
            value={value}
        />
    )
}
