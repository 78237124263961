import {ReactElement} from 'react'
import Input from '../../../../Components/Input/Input'
import InputPassword from '../../../../Components/Input/InputPassword'
import InputType from '../../../../Types/InputType'

export default function SignUpStepA(props: any):ReactElement {
    const {data} = props

    return <>
        <Input value={'fullname' in data ? data['fullname']:''} name='fullname' type={InputType.Text} label={'Name'} placeholder={'Name'} required />
        <Input value={'email' in data ? data['email']:''} name='email' type={InputType.Email} label={'Email'} placeholder={'Email'} required />
        <InputPassword value={'password' in data ? data['password']:''} name='password' type={InputType.Password} required />
        <InputPassword value={'confirmpassword' in data ? data['confirmpassword']:''} name='confirmpassword' label="Confirm Password" placeholder="Confirm Password" type={InputType.Password} required />
    </>
}
