import React, {useContext, useState} from 'react'
import './SignUp.css'
import Stepper from '../../../Components/Stepper/Stepper'
import SignUpStepA from './Steps/SignUpStepA'
import SignUpStepB from './Steps/SignUpStepB'
import SignUpStepC from './Steps/SignUpStepC'
import { useNavigate  } from 'react-router-dom'
import { useAuth } from '../../../Contexts/AuthContext'
import { useConfiguration } from '../../../Contexts/ConfigurationContext'
import { useTranslation } from '../../../Contexts/TranslationContext'

export default function SignUp() {
    const { setAuthenticated } = useAuth();
    const navigate = useNavigate();

    const { translations } = useTranslation();
    const configuration = useConfiguration();

    const [processing, setProcessing] = useState(false)
    const reg  = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    
    async function onSignUp(data: any) {
        setProcessing(true)

        // if (!data['email'] || !reg.test(data['email'])) return
        if (!data['password'] || !data['confirmpassword'] || data['password'] !== data['confirmpassword']) return
        
        //const res = await register(data)
        const res = {success: true}
        if (res.success === true) {
            setAuthenticated(true)
            navigate('/account')
        } else {
            alert('Error signin up.')
        }

        setProcessing(false)
    }

    // TODO: Add steps, replace world with resource
    return <main className='SignUp row center center-v w-100 mt-0 pt-0'>
        <div className='SignUp-Form column center grow-1'>
            <section className='column flex-start'>
                <div className='center-v'>
                    <h1 className='pl-2 mb-1 fg-color-2-text'>{configuration.app_name}</h1>
                    <h1 className='title-auth shine-animation-text mb-8'>{translations.signup ? translations.signup.toUpperCase() : ""}</h1>
                </div>
                <div className='w-100'>
                    <Stepper form={true} btnFinish={"Sign Up"} loading={processing} onFinish={onSignUp}>
                        <SignUpStepA />
                        <SignUpStepB />
                        <SignUpStepC />
                    </Stepper>
                </div>
            </section>
        </div>

    </main>
}
