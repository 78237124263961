import {ReactElement} from 'react'
import Button from '../../../Components/Buttons/Button'
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import { useConfiguration } from '../../../Contexts/ConfigurationContext'
import { useModal } from '../../../Contexts/ModalContext'
import { useTranslation } from '../../../Contexts/TranslationContext'
import NavLinks from '../../../Design/NavLinks/NavLinks'
import ThemeToggler from '../../../Design/ThemeToggler/ThemeToggler'
import { useExperience } from '../../../Contexts/ExperienceContext'

export default function Header() {
    const configuration = useConfiguration();
    const location = useLocation();
    const {isFirstTimeOnWebsite} = useExperience();
    const {setModal} = useModal();
    const {language, toggleLanguage, translations} = useTranslation();
    const { isRegistered } = useExperience()

    const {routes} = configuration

    function getPage() {
        const pageName: string = location.pathname.slice(1)
        if (routes.all.includes(pageName))
            return pageName

        return undefined;
    }

    function isAuthPage() {
        const page = getPage();
        return routes.authentication.register.value.includes(page) || routes.authentication.login.value.includes(page) || routes.authentication.lobby.value.includes(page)
    }

    function isLoginPage() {
        const page = getPage();
        return routes.authentication.login.value.includes(page)
    }

    function isRegisterPage() {
        const page = getPage();
        return routes.authentication.register.value.includes(page)
    }

    function isHomePage() {
        const page = getPage();
        return routes.default.value.includes(page);
    }

    function onClickBurger() {
        setModal(<NavLinks />);
    }

    return <>{!isFirstTimeOnWebsite &&
        <header className='header-animation pb-1 blur'>
            {displayOptionButtons()}
            {!isAuthPage() && <h1 className='mr-3'>{getPage()}</h1>}
            {displayAuthenticationButtons()}
        </header>
    }</>

    function displayOptionButtons(): ReactElement {
        return <div className='row ml-1 mt-2'>
            <Button onCLick={onClickBurger} className='xl' color='theme' icon={faBarsStaggered} size={'xl'} />
            <ThemeToggler />
            <Button onCLick={toggleLanguage} className='' color='theme' size='xl'><b>{language.toUpperCase()}</b></Button>
        </div>
    }

    function displayAuthenticationButtons(): ReactElement {
        return <div className='row mr-1 mt-1 gap-4'>
            {!isHomePage() && <Button style='border' path={configuration.routes.default.value[0]}>{translations.home}</Button>}
            {!isLoginPage() && <Button style='border' path={configuration.routes.authentication.login.value[0]} animation={isRegistered} >{translations.signin}</Button>}
            {!isRegisterPage() && <Button style='border' path={configuration.routes.authentication.register.value[0]} animation={!isRegistered}>{translations.signup}</Button>}
        </div>
    }
}