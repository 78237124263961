import {ReactElement} from 'react'
import Input from '../../../../Components/Input/Input'
import InputType from '../../../../Types/InputType'

export default function SignUpStepB(props: any): ReactElement {
    const {data} = props

    return <>
        <Input value={'country' in data ? data['country']:''} name='country' type={InputType.Text} label={'Country'} placeholder={'Country'} />
        <Input value={'state' in data ? data['state']:''} name='state' type={InputType.Text} label={'State'} placeholder={'State'} />
        <Input value={'city' in data ? data['city']:''} name='city' type={InputType.Text} label={'City'} placeholder={'City'} />
    </>
}
