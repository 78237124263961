import {ReactElement} from 'react'
import './NavLinks.css'
import { useConfiguration } from '../../Contexts/ConfigurationContext'
import { useTranslation } from '../../Contexts/TranslationContext';
import { Link } from 'react-router-dom';
import { useModal } from '../../Contexts/ModalContext';
import { useAuth } from '../../Contexts/AuthContext';

export default function NavLinks(): ReactElement {

    const { closeModal } = useModal();
    const { authenticated } = useAuth();
    const { translations } = useTranslation();
    const configuration = useConfiguration();

    function onClickLink() {
        closeModal();
    }

    return <div className='NavLinks column gap-24'>
        {configuration.routes.nav.map((route: any, i: number) => {
            return <Link key={`nav-route-${i}`} onClick={onClickLink} to={route.value[0]}>{translations[route.key]}</Link>
        })}

        {
            authenticated ? <>
                {configuration.routes.private.map((route: any, i: number) => {
                    return <Link key={`private-route-${i}`} onClick={onClickLink} to={route.value[0]}>{translations[route.key]}</Link>
                })}
                <Link onClick={onClickLink} to={configuration.routes.authentication.logout.value[0]}>{translations[configuration.routes.authentication.logout.key]}</Link>
            </>
            : <>
                <Link onClick={onClickLink} to={configuration.routes.authentication.login.value[0]}>{translations[configuration.routes.authentication.login.key]}</Link>
                <Link onClick={onClickLink} to={configuration.routes.authentication.register.value[0]}>{translations[configuration.routes.authentication.register.key]}</Link>
            </>
        }
    </div>
}
