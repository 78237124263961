import {ReactElement, useState} from "react";
import './App.css';
import AppContext from "./AppContext";
import Body from "./Html/Body";

export default function App(): ReactElement {
    const [loaded, setLoaded] = useState(true)

    return (
        <AppContext onAppContextLoaded={() => setLoaded(true)}>
            { loaded ? <Body /> : "Loading"}
        </AppContext>
    )
}