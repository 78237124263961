import configuration from './Configuration.json'
import { faLifeRing, faEnvelope, faCircleUser } from '@fortawesome/free-regular-svg-icons'
import { faDollarSign, faCity } from '@fortawesome/free-solid-svg-icons'

export default {
    ...configuration,
    navIcons: [
        [faLifeRing],
        [faEnvelope],
        [faCity],
        [faDollarSign],
        [faCircleUser]
    ]
}

/*
faBuilding, faUser, faBell, faAddressBook, faMoneyBill1, faChartBar
faChartLine, faToolbox, faBars, faArrowTrendUp
*/