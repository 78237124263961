import { Route, Routes, Navigate } from 'react-router-dom'

import Home from '../../../Pages/Public/Home'
import Account from '../../../Pages/Private/Account'
import Contacts from '../../../Pages/Public/Contact'
import Lobby from '../../../Pages/Authentication/Lobby'
import SignIn from '../../../Pages/Authentication/SignIn/SignIn'
import SignOut from '../../../Pages/Authentication/SignOut/SignOut'
import SignUp from '../../../Pages/Authentication/SignUp/SignUp'
import Unknown from '../../../Pages/Public/Unknown'
import Contact from '../../../Pages/Public/Contact'
import Services from '../../../Pages/Public/Services'
import Test from '../../../Pages/Public/Test'

import { useAuth } from '../../../Contexts/AuthContext'

export default function Main() {
    const { authenticated } = useAuth();

    return <Routes>
        <Route path="" element={ <Home /> } />
        <Route path="home" element={ <Navigate replace to="/" /> } />
        <Route path="contact" element={ <Contact /> } />
        <Route path="services" element={ <Services /> } />
        <Route path="lobby" element={ <Lobby /> } />
        <Route path="signin" element={ <SignIn/> }/>
        <Route path="signup" element={ <SignUp/> }/>
        <Route path="test" element={ <Test/> }/>
        <Route path="*" element={ <Unknown/> } />
        
        { authenticated && 
        <Route>
        <Route path="signout" element={ <SignOut/> }/>

        <Route path="account" element={ <Account/> }/>
        <Route path="contacts" element={ <Contacts/> }/>
        </Route>
        }
    </Routes>;
}