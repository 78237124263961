
import React, { createContext, useState, useContext, useEffect } from 'react';
import Configuration from '../Configurations/Configuration'
const LS_THEME = localStorage.getItem(Configuration.var_local_storage_theme);

export interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
  toggleTheme: () => void;
}

const initialThemeContext: ThemeContextType = {
  theme: LS_THEME ?? Configuration.default_theme,
  setTheme: () => {},
  toggleTheme: () => {}
};

export const ThemeContext = createContext<ThemeContextType>(initialThemeContext);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<string>(() => {
    const storedTheme = localStorage.getItem(Configuration.var_local_storage_theme);
    return storedTheme ?? Configuration.default_theme;
  });

  useEffect(() => {
    localStorage.setItem(Configuration.var_local_storage_theme, theme);
  }, [theme])
  

  function toggleTheme() {
    setTheme(currentTheme => (currentTheme === 'light' ? 'dark' : 'light'));
  }

  const contextValue: ThemeContextType = {
    theme: theme,
    setTheme: setTheme,
    toggleTheme: toggleTheme
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};
