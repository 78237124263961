import React, { useEffect, useRef, useState } from 'react'
import './Form.css'

export default function Form(props) {
    const { className, children, step, setData, setValid, required } = props
    const formRef = useRef(null);
    const [formData, setFormData] = useState({})
    const [requiredFields, setRequiredFields] = useState([])
    const [error, setError] = useState(required)
    const [checkboxes, setCheckboxes] = useState([])
    const [radios, setRadios] = useState([])
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (!formRef.current) return
        setDatas()
        checkValidity(formData)
    }, [step])

    useEffect(() =>{
        console.log(error)
        if (!error) setShowError(false)
    }, [error])

    
    useEffect(() => {
        checkValidity(formData)
    }, [requiredFields])


    function setDatas() {
        let object = {...formData}
        let inputs = []
        Array.from(formRef.current.elements)
            .filter((element) => element.tagName === 'INPUT')
            .forEach((input) => {
                object[input.name] = object[input.name] ?? null
                if ((input.placeholder && input.placeholder.includes('*')) || input.required) inputs.push(input.name)
            })

        let tcheckboxes = []
        let tradios = []
        Array.from(formRef.current.elements)
            .filter((element) => element.tagName === 'FIELDSET')
            .forEach((fieldset) => {
                for (let input of fieldset.elements) {
                    if (input.type === 'checkbox' && !checkboxes.includes(input.name)) tcheckboxes.push(input.name)
                    if (input.type === 'radio' && !radios.includes(input.name)) tradios.push(input.name)
                }
            })

        setCheckboxes(tcheckboxes)
        setRadios(tradios)
        setRequiredFields(inputs)
        if (setData) setData(object)
        setFormData(object)
    }
    
    function onSubmit(event) {
        event.preventDefault()
        checkValidity(formData)

        if (error) {
            setShowError(true)
            return
        }

        if (props.onSubmit) props.onSubmit(formData)
    }

    function onChange(event) {
        let object = {...formData}
        object[event.target.name] = event.target.validity.valid ? event.target.value : ''
        console.log(event.target.validity)
        setFormData(object)
        if (setData) setData(object)
        checkValidity(object)
    }

    function setValidity(validity) {
        if (setValid) setValid(validity)
        setError(!validity)
    }

    function checkValidity(dataToValidate) {
        if (required) { 
            for (let key in dataToValidate) {
                if (requiredFields.includes(key) && !dataToValidate[key]) {
                    console.log(key, dataToValidate[key])
                    setErrorMessage('Fields marked with an * should be filled.')
                    setValidity(false)
                    
                    return
                }
            }
        }

        if (checkboxes.length > 0) {
            let checked = 0
            for (let key of checkboxes) {
                if (dataToValidate[key] === "true") checked++
            }
            if (checked <= 0) {
                setErrorMessage('At least one field should be checked.')
                setValidity(false)
                return
            }
        }

        if ('confirmpassword' in dataToValidate) {
            if (dataToValidate['confirmpassword'] !== dataToValidate['password']) {
                setErrorMessage('Field password and confirm password should be indentical.')
                setValidity(false)
                return
            }
        }

        setValidity(true)
        setErrorMessage('')
    }

    return <form ref={formRef} className={`Form ${className}`} onChange={onChange} onSubmit={onSubmit} noValidate>
        {children}
        {<p className={`Form-Error w-100 text-right c-negative ${(showError && error) ? 'visible' : 'invisible'}`}>{errorMessage}</p>}
    </form>
}
