import {createContext, ReactElement, useContext, useEffect, useState} from 'react'
import { useConfiguration } from './ConfigurationContext';

export interface ExperienceContextType {
    isFirstTimeOnWebsite: boolean;
    setIsFirstTimeOnWebsite: (value: boolean) =>  void;
    isRegistered: boolean;
    setIsRegistered: (value: boolean) =>  void;
}

const initialAuthContext: ExperienceContextType = {
    isFirstTimeOnWebsite: true,
    setIsFirstTimeOnWebsite: () => {},
    isRegistered: false,
    setIsRegistered: () => {}
}

const ExperienceContext = createContext<ExperienceContextType>(initialAuthContext)

export function useExperience() {
    const context = useContext(ExperienceContext);

    if (!context)
        throw new Error('useExperience must be used within a ExperienceProvider');

    return context;
};

export function ExperienceProvider(props: any): ReactElement {
    const Configuration = useConfiguration();
    const {children} = props
    const [isFirstTime, setIsFirstTime] = useState<boolean>(() => {
        const LS_XP_IS_FIRST_TIME = localStorage.getItem(Configuration.var_local_storage_experience_is_first_time);
        return LS_XP_IS_FIRST_TIME === "true" ? true : false
    });
    const [isRegistered, setIsRegistered] = useState<boolean>(() => {
        const LS_XP_IS_REGISTERED = localStorage.getItem(Configuration.var_local_storage_experience_is_registered);
        return LS_XP_IS_REGISTERED === "true" ? true : false
    });
    
    useEffect(() => {
        localStorage.setItem(Configuration.var_local_storage_experience_is_first_time, isFirstTime.toString())
    }, [isFirstTime])

    useEffect(() => {
        localStorage.setItem(Configuration.var_local_storage_experience_is_registered, isRegistered.toString())
    }, [isRegistered])

    const experience = {
        isFirstTimeOnWebsite: isFirstTime, 
        setIsFirstTimeOnWebsite: setIsFirstTime,
        isRegistered: isRegistered,
        setIsRegistered: setIsRegistered
    }

    return (
        <ExperienceContext.Provider value={experience}>
            {children}
        </ExperienceContext.Provider>
    );
}