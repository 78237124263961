import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Input.css'
import InputType from '../../Types/InputType'
import { isNull } from '../../Utilities/validation'

export default function Input(props) {
    const {button, id, placeholder, pattern, required, label, type, min, max, name, handleButton, errorMessage} = props
    const inputRef = useRef(null)
    const [displayButton, setDisplayButton] = useState(false)
    const [error, setError] = useState(false)
    const [value, setValue] = useState(props.value)
    const [focused, setFocused] = useState(false)
 
    const handleChange = useCallback((event) => {
        if (event && event.target) setValue(event.target.value)

        if (!isNull(event) && event.target.value.length > 0) setDisplayButton(true)
        else setDisplayButton(false)

        if (!isNull(inputRef.current) && event && event.target.value.length > 0 && error) setError(!inputRef.current.checkValidity())
        else setError(false)
    }, [error])

    function onFocus() { setFocused(true) }

    useEffect(() => { handleChange() }, [error])

    return (
        <div className='Input'>
            <input
                ref={inputRef}
                className={`Input-input`}
                id={id}
                type={isNull(type) ? InputType.Text : type}
                placeholder={placeholder ? placeholder + (required?'*':''): ''}
                pattern={pattern}
                required={focused ? required : false}
                onChange={handleChange}
                onFocus={onFocus}
                min={min}
                max={max}
                name={name}
                defaultValue={value}
            />

            { label && value &&
            <label className='Input-label'>{label}</label>
            }

            { button && displayButton &&
            <div className='Input-icon' onClick={handleButton}><FontAwesomeIcon color='rgb(50,50,50)' icon={button} /></div>
            }

            <p className={ error ? 'Input-error d-block visible' : 'Input-error d-none' }>{errorMessage}</p>
        </div>
    )
}