import {createContext, useContext, useEffect, useState} from 'react'
import Configuration from '../Configurations/Configuration'
import EN from '../Configurations/Translations/en.json'

const LS_LANGUAGE_CODE = localStorage.getItem(Configuration.var_local_storage_language_code)
const DEFAULT_LANGUAGE_CODE = LS_LANGUAGE_CODE ?? Configuration.default_language

export interface TranslationContextType {
    language: string;
    setLanguage: (language: string) => void;
    toggleLanguage: () => void,
    translations: any;
}

const initialTranslationContext: TranslationContextType = {
    language: LS_LANGUAGE_CODE ?? DEFAULT_LANGUAGE_CODE,
    setLanguage: () => {},
    toggleLanguage: () => {},
    translations: EN
}

export const TranslationContext = createContext<TranslationContextType>(initialTranslationContext);

export function useTranslation() {
    const context = useContext(TranslationContext);

    if (!context) {
      throw new Error('useTranslation must be used within a TranslationProvider');
    }
    return context;
}

  export function TranslationProvider(props: any) {
    const { children } = props;

    const [language, setLanguage] = useState<string>(() => {
      const storedLang = localStorage.getItem(Configuration.var_local_storage_language_code);
      return storedLang ?? Configuration.default_language;
    });

    const [translations, setTranslations] = useState<string>(() => {
      const storedLang = localStorage.getItem(Configuration.var_local_storage_language_code);
      return storedLang ?? Configuration.default_language;
    });

    
    useEffect(() => {
      localStorage.setItem(Configuration.var_local_storage_language_code, language);
      loadTranslations(language)
    }, [language])

    async function loadTranslations(language: string) {
      if (Configuration.languages.includes(language)) {
        const translations = await import('../Configurations/Translations/'+language+'.json')
        setTranslations(translations);
      }
    }

    function toggleLanguage() {
      setLanguage((current: string) => {
        const index: number = Configuration.languages.indexOf(current)

        if (index === -1 || index === Configuration.languages.length-1)
          return Configuration.languages[0]

        return Configuration.languages[index+1]
      });
    }
  
    const contextValue: TranslationContextType = {
        language: language,
        setLanguage: setLanguage,
        toggleLanguage: toggleLanguage,
        translations: translations
    };
  
    return (
      <TranslationContext.Provider value={contextValue}>
        {children}
      </TranslationContext.Provider>
    );
  }