import {ReactElement, useEffect} from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useTheme } from '../../Contexts/ThemeContext'
import Header from './Body/Header'
import Nav from '../../Components/Nav/Nav'
import Footer from './Body/Footer'
import Main from './Body/Main'
import Modal from '../../Design/Modal/Modal'
import { useModal } from '../../Contexts/ModalContext'
import { useTranslation } from '../../Contexts/TranslationContext'

export default function Body(): ReactElement {
    const { theme } = useTheme();

    useEffect(() => {
        document.body.setAttribute("data-theme", theme)
    }, [theme])

    const {translations} = useTranslation();
    const {modal, setModal} = useModal();

    return <>
        {translations &&
            <BrowserRouter>
                <Header />
                <Main />
                <Nav />
                <div id='App-Modals'>
                    {modal && <Modal onClose={() => setModal(undefined)}>
                        {modal}
                    </Modal>}
                </div>
                <Footer />
            </BrowserRouter>
        }
    </>
}