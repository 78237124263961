import { useEffect, useState} from "react"
import { useLocation } from 'react-router-dom'
import Button from '../Buttons/Button'
import { useConfiguration } from "../../Contexts/ConfigurationContext"
import { useAuth } from '../../Contexts/AuthContext'

export default function Nav() {
    
    const location = useLocation();
    const configuration = useConfiguration();
    const [notifications, setNotifications] = useState(null)
    const {authenticated} = useAuth();

    const [tabs] = useState(() => {
        const tabs = []
        configuration.routes.nav.map((path, i) => {
            tabs.push({
                path: path,
                icon: configuration.navIcons[i]
            })
        })
        return tabs
    })


    useEffect(() => {
        async function getNotifications() {
            // await services.GetNotifications()
            setNotifications([1])
        }
        getNotifications()
    }, [])

    function isActive(path) {
        return path === location.pathname || path === `/${location.pathname.split("/")[2]}`
    }

    return (notifications && location.pathname !== '/' && location.pathname !== '/home' && authenticated === true) && (
        <nav>
            <section>
                
                {tabs.map((tab, i) => { 
                    return (
                        <Button key={i}
                            className={isActive(i) ? "circle": ""}
                            color={"rgb(70,70,70)"}
                            icon={tab.icon[0]}
                            path={tab.path}
                            dot={(tab.path === '/contacts' || tab.path === '/messages' || tab.path === '/notifications') && notifications.length > 0}
                        />
                    )
                })}
            </section>
        </nav>
    )
}
