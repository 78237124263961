import {ReactElement} from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { faUserTie, faUser } from '@fortawesome/free-solid-svg-icons'

export default function SignUpStepC(props: any): ReactElement {
    const { data } = props

    return <div className='column center-v'>
        <label className='mb-4' htmlFor={undefined}>Chose your account type(s)</label>
        <fieldset name="type" className='row center-h'>    
            <Checkbox icon={faUserTie} value={'landlord' in data ? data['landlord']:''} name='landlord' label='Landlord' />
            <Checkbox icon={faUser} value={'tenant' in data ? data['tenant']:''} name='tenant' label='Tenant' />
        </fieldset>
    </div>
}
