import {ReactElement, useContext} from 'react'
import Button from '../../Components/Buttons/Button'
import ButtonType from '../../Types/ButtonType'
import { useConfiguration } from '../../Contexts/ConfigurationContext'

export default function Lobby(): ReactElement {
        
    const configuration = useConfiguration();

    return <main className='SignIn row center center-v w-100 mt-0 pt-0'>
        <div className='SignUp-Form column center grow-1'>
            <section className='center-v'>
                <div>
                    <h1 className='pl-2 mb-1 fg-color-2-text'>{configuration.app_name}</h1>
                    <h1 className='title-auth shine-animation-text mb-8'>WEL- COME</h1>
                </div>
                <div className='w-100'>
                    <Button path='/signup' type={ButtonType.Action} display='primary' className='mt-8' >Sign Up</Button>
                    <Button path='/signin' type={ButtonType.Action} display='secondary' className='mt-4' >Sign In</Button>
                </div>
            </section>
        </div>
    </main>
}
