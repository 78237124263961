import {createContext, ReactElement, useContext, useEffect, useState} from 'react'
import Configuration from '../Configurations/Configuration'

const ConfigurationContext = createContext<any>(undefined)

export function useConfiguration() {
    const context = useContext(ConfigurationContext);

    if (!context)
        throw new Error('useConfiguration must be used within a ConfigurationProvider');

    return context;
};

export function ConfigurationProvider(props: any): ReactElement {
    const {children} = props
    const [configuration] = useState<any>(Configuration);

    return (
        <ConfigurationContext.Provider value={configuration}>
            {children}
        </ConfigurationContext.Provider>
    );
}