import { useEffect, useState } from "react"
import './Button.css'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonType from "../../Types/ButtonType"
import {useTheme} from "../../Contexts/ThemeContext"


function Button(props) {
    const { children, className, onCLick, path, shape, icon, dot, size, type, style, disabled, animation } = props
    const {theme} = useTheme();
    const display = props.display ?? 'primary';
    const [color, setColor] = useState(props.color === "theme" ? (theme === 'dark' ? 'white' : 'black') : props.color)
    const navigate = useNavigate()

    useEffect(() => {
        if (props.color !== "theme") return;

        setColor(theme === 'dark' ? 'white' : 'black')
    }, [theme])

    function handleClick() {
        if (disabled) return;
        if (onCLick) props.onCLick();
        if (path) navigate(path);
    }

    function getClassName() {
        let cn = ""
        className ? cn += `${className} ` : cn += ""
        shape ? cn += `${shape} ` : cn += ""
        size ? cn += `${size} ` : cn += "xl "
        return cn
    }

    return (
        type === ButtonType.Action ?
        <button type="submit" className={`Button ${getClassName()} ${style === 'border'?'Button-Border':''} ${display === 'primary' ? 'Primary' : 'Secondary'} ${!disabled ? 'Button-Enabled' : 'Button-Disabled'}`}  onClick={handleClick}>
            {icon && <FontAwesomeIcon color={color} icon={icon} size={size ? size : 'xl'}/>}
            {dot && <span className="dot"><span></span></span>}
            <span>{children}</span>
        </button>
        :
        <div className={`Button ${getClassName()}  ${style === 'border'?'Button-Border':''} ${animation ? 'Button-Animation' : ''}`} onClick={handleClick}>
            {icon && <FontAwesomeIcon color={color} icon={icon} size={size ? size : 'xl'}/>}
            {dot && <span className="dot"><span></span></span>}
            <span className={(icon && children && children.length > 0) ? 'ml-1' : ''}>{children}</span>
        </div>
    )
}

export default Button