import React from "react";
import { useLocation } from "react-router-dom";
import { useConfiguration } from "../../../Contexts/ConfigurationContext";
import Configuration from "../../../Configurations/Configuration";

function Footer(props: any) {

    const configuration = useConfiguration();
    const location = useLocation();
    function isAuthPage() {
        return location.pathname === "/signup" || location.pathname === "/signin" || location.pathname === "/lobby"
    }

    return <>{!isAuthPage() && <>
        <footer className="row center">
            {Configuration.app_name} © {new Date().getFullYear()}
        </footer>
    </>}</>
}

export default Footer