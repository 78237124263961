import {createContext, ReactElement, useContext, useState} from 'react'

export interface AuthContextType {
    authenticated: any;
    setAuthenticated: (auth: boolean) => void;
    user: any;
    setUser: (user: any) => void;
}

const initialAuthContext: AuthContextType = {
    authenticated: false,
    setAuthenticated: (auth: boolean) => {},
    
    user: null,
    setUser: (user: any) => {}
}
const AuthContext = createContext<AuthContextType>(initialAuthContext)

export function useAuth() {
    const context = useContext(AuthContext);

    if (!context)
        throw new Error('useAuth must be used within a AuthProvider');

    return context;
};

export function AuthProvider(props: any): ReactElement {
    const {children} = props
    const [authenticated, setAuthenticated] = useState<any>(false);
    const [user, setUser] = useState<any>(null);

    const contextValue: AuthContextType = {
        authenticated: authenticated,
        setAuthenticated: setAuthenticated,
        user: user,
        setUser: setUser
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
}