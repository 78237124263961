import {ReactElement} from 'react'
import { useAuth } from '../../Contexts/AuthContext'

export default function Account(): ReactElement {
    const { authenticated } = useAuth();

    return <main>
        <p>Connected: {authenticated.toString()}</p>
        <p>Account</p>
    </main>
}
