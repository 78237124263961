import{ ReactElement } from "react"
import { useTranslation } from "../../Contexts/TranslationContext";
import Button from "../../Components/Buttons/Button";

export default function Unknown(): ReactElement {

    const {translations} = useTranslation();

    return (
        <main className="h-vh-100 center">
            <h1>404 {translations.page_not_found}</h1>
            <br />
            <Button style='border' path="/" >{translations.go_home}</Button>
        </main>
    )
}