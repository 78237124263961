export default [
    {
        name: "JC App",
        description: "click_to_know_what_s_up",
        link: "https://jc-app.konjuu.com"
    },
    {
        name: "raceouzi.com",
        description: "showcase_website_for_race_ouzi", 
        link: "https://raceouzi.com"
    },
    {
        name: "My Doors",
        description: "unavailable",
        link: ""
    },
    {
        name: "MAFIA Roblox Game",
        description: "unavailable",
        link: ""
    }
]