import {ReactElement} from 'react'

export default function Services(): ReactElement {
  return (
    <main className='h-vh-100 column mt-0 center'>
      <div className='w-90'>
        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro labore nostrum deleniti fugiat consequatur minus vitae autem rem, quisquam suscipit sapiente similique. Repudiandae dolorem veniam eveniet sed exercitationem nobis iure.</p>
      </div>
    </main>
  )
}
