import {createContext, ReactElement, useContext, useEffect, useState} from 'react'

export interface ModalContextType {
    modal: any;
    setModal: (modal: any) => void;
    closeModal: () => void;
}

const initialModalContext: ModalContextType = {
    modal: undefined,
    setModal: () => {},
    closeModal: () => {}
}

const ModalContext = createContext<ModalContextType>(initialModalContext)

export function useModal() {
    const context = useContext(ModalContext);

    if (!context)
        throw new Error('useModal must be used within a ModalProvider');

    return context;
};

export function ModalProvider(props: any): ReactElement {
    const {children} = props
    const [modal, setModal] = useState<ReactElement|undefined>(undefined);

    function closeModal() {
        setModal(undefined);
    }

    const contextValue: ModalContextType = {
        modal: modal,
        setModal: setModal,
        closeModal: closeModal
    };

    return (
        <ModalContext.Provider value={contextValue}>
            {children}
        </ModalContext.Provider>
    );
}