import {ReactElement} from 'react'
import List from '../../Components/List/List';
import Card from '../../Design/Card/Card';
import KonjuuApps from '../../Data/KonjuuApplications'
import { useTranslation } from '../../Contexts/TranslationContext';
import { useConfiguration } from '../../Contexts/ConfigurationContext';
import { useExperience } from '../../Contexts/ExperienceContext';

export default function KonjuuApplications(): ReactElement {

    const {translations} = useTranslation();
    const Configuration = useConfiguration();
    const { isFirstTimeOnWebsite, setIsFirstTimeOnWebsite } = useExperience();

    return <>
        <h3 className='mb-8'>{translations.our_applications}</h3>
        <List>
            {KonjuuApps.map((app: any) => {
                return <Card key={app.name} link={app.link}>
                <>
                    <b>{app.name}</b>
                    <p>{translations[app.description]}</p>
                </>
            </Card>
            })}
        </List>
    </>;
}
