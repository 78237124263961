import React, { useState } from "react";
import Form from "../Form/Form";
import './Stepper.css'
import Button from "../Buttons/Button";
import ButtonType from "../../Types/ButtonType";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

// TOO: Change hardcoded strings
export default function Stepper(props) {
    const {children, btnBack, btnNext, btnFinish, title, description, form, loading} = props
    const textBack = btnBack ?? 'Back'
    const textNext = btnNext ?? 'Next'
    const textFinish = btnFinish ?? 'Finish'
    const [step, setStep] = useState(0)
    const [data, setStepperData] = useState({})

    const childWithData = React.Children.map(children[step], (child) => {
        return React.cloneElement(child, { data:data });
    });

    const [valid, setValid] = useState(false)

    function onBack() {
        if (step > 0) setStep(step-1)
    }

    function onNext() {
        if (step < children.length-1) setStep(step+1)
        else {
            
        }
    }

    function onFinish() {
        if (props.onFinish) props.onFinish(data)
    }

    return (
        <div className='Stepper w-100'>
            {title ?? ''}
            {description ?? ''}

            {form &&
            <Form step={step} setData={setStepperData} setValid={setValid} required>
                {childWithData}

                <div className={`row center-v ${step>0?'space-between':'end'}`}>
                    {step > 0 && <Button type={ButtonType.Nav} action={onBack} icon={faArrowLeft} size='sm' className='mt-6' disabled={loading} >{textBack}</Button>}
                    <Button display={'Secondary'} type={ButtonType.Action} action={step === children.length-1 ? onFinish : onNext} disabled={!valid || loading} size='lg' className='mt-6 w-45' >
                        {step === children.length - 1 ? textFinish : textNext}
                    </Button>
                </div>
            </Form>}
        </div>
    )
}