import {ReactElement} from 'react'
import { useTheme } from '../../Contexts/ThemeContext'
import Button from '../../Components/Buttons/Button';
import { faMoon, faSun } from '@fortawesome/free-regular-svg-icons';

export default function ThemeToggler(): ReactElement {

    const {theme, toggleTheme} = useTheme();

    return (
        <Button onCLick={toggleTheme} icon={theme==='light'?faSun:faMoon} color="theme" size={'xl'} />
    )
}
