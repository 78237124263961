import React, { ReactElement } from 'react'

export default function Test(): ReactElement {
    return (
        <main className='column center'>
            <div className='test-div'>
                <span className='test-title'>TEST</span>
            </div>
            <br />
            <br />
            <br />
        </main>
    )
}
