import {ReactElement} from 'react'
import { useConfiguration } from '../../Contexts/ConfigurationContext'

export default function Contact(): ReactElement {
    const configuration = useConfiguration();

    return <main className='h-vh-100 column mt-0 center'>
        <p>{configuration.support_email}</p>
    </main>
}
