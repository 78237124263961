import {ReactElement} from 'react'
import './Card.css'
import { useNavigate } from 'react-router-dom';

interface CardProps {
    children?: ReactElement;
    to?: string;
    link?: string;
    onClick?: () => void;
}

export default function Card(props: CardProps): ReactElement {

    const { children } = props;
    const navigate = useNavigate();

    function onClick() {
        if (props.onClick) props.onClick!();
        if (props.to) navigate(props.to!);
        else if (props.link) window.location.href = props.link;
    }

    return (
        <div 
            className='Card'
            onClick={onClick}
        >
            {children}
        </div>
    )
}
