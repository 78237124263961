import { ReactElement, useEffect, useRef } from 'react'
import { useTheme } from '../../Contexts/ThemeContext';
import { useTranslation } from '../../Contexts/TranslationContext';
import { useConfiguration } from '../../Contexts/ConfigurationContext';
import { useExperience } from '../../Contexts/ExperienceContext';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import KonjuuApplications from '../../Components/Custom/KonjuuApplications'; 

export default function Home(): ReactElement {
    const {toggleTheme} = useTheme();
    const {translations} = useTranslation();
    const Configuration = useConfiguration();
    const { isFirstTimeOnWebsite, setIsFirstTimeOnWebsite } = useExperience();

    const appsSectionRef = useRef<null | HTMLHeadingElement>(null);

    useEffect(() => {
        if (!isFirstTimeOnWebsite) return;

        setTimeout(toggleTheme, 4500);
        setTimeout(() => {
            setIsFirstTimeOnWebsite(false);
            scrollToApps(); 
        }, 17500);
    }, [])

    function scrollToApps(): void {
        if (appsSectionRef && appsSectionRef.current)
            appsSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return (
        <main className={`column mt-0 center${isFirstTimeOnWebsite? " main-landing" : ""}`}>
            {isFirstTimeOnWebsite ?
            <div className='w-100 h-vh-100 anim-landing'>
                <h1 className='title-landing'>{Configuration.app_name}</h1>
                <h2 className='subtitle1-landing mt-4'>{translations.start_your_journey_with_us}</h2>
                <h2 className='subtitle2-landing'>{translations.check_out_our_apps}</h2>
                <h2 className='subtitle3-landing'>{translations.we_make_your_like_easier}</h2>
                <h2 className='subtitle4-landing'>{translations.because_not_everything_is_black_and_white}</h2>
                <h1 className='title2-landing title2-landing-anim'>{Configuration.app_name}</h1>
                <h2 className='subtitle-landing mt-4 subtitle-landing-anim'>{`< ${translations.hello_world} />`}</h2>
            </div>
            :
            <div className='w-100 max-h-vh-100 column center'>
                <h1 className='title2-landing'>{Configuration.app_name}</h1>
                <h2 className='subtitle-landing mt-4'>{`< ${translations.hello_world} />`}</h2>
                <div onClick={scrollToApps} style={{position: "absolute", bottom: 15}}>
                    <FontAwesomeIcon icon={faChevronDown} bounce size='2xl' />
                </div>
            </div>
            }

            <div className='column center w-100 h-vh-100' ref={appsSectionRef} >
                <KonjuuApplications />
            </div>
        </main>
    );
}